<template>
  <div class="d-c">
    <div class="d-c-title">发现企业</div>
    <div class="d-c-middle-wrap">
      <div class="d-c-middle-title">您可以选取或创造一些招商条件, 对企业库总的企业进行筛选, 发现更多优质企业</div>
      <el-button type="primary" @click="$router.push('/createBusinessCondition')" class="d-c-middle-btn">创建招商条件</el-button>
    </div>
    <div class="d-c-bottom">
      <div class="d-c-bottom-title">以下是您的历史招商需求, 大数据实时为您分析推荐优秀项目</div>
      <el-radio-group v-model="status" @change="d_c_change">
        <el-radio :label="''">全部</el-radio>
        <el-radio :label="1">正在追踪</el-radio>
        <el-radio :label="0">暂停追踪</el-radio>
      </el-radio-group>
      <el-table class="discover-table" :data="historyCompanyData" stripe style="margin-top: 20px">
        <el-table-column align="center" prop="title" label="招商需求" width="180"></el-table-column>
        <el-table-column align="center" prop="intro" label="招商需求描述"></el-table-column>
        <el-table-column align="center" prop="project_count" width="150" label="推荐企业/项目"></el-table-column>
        <el-table-column align="center" prop="new_count" label="推荐企业/项目" width="200"  :render-header="renderTypeHeader">
        </el-table-column>
        <el-table-column align="center" prop="created_at" label="创建时间" width="160"></el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="handleClickView(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small" @click="editClickView(scope.row)">编辑</el-button>
            <el-button @click="pauseClick(scope.row)" v-if="scope.row.status === 1" type="text" size="small">暂停追踪</el-button>
            <el-button @click="pauseClick(scope.row)" v-else type="text" size="small">追踪</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <Page @currentPageChange="currentPageChange"
          :pageSize="meta.per_page"
          :page="meta.current_page"
          :total="meta.total" style="text-align: center;margin-top: 10px"></Page>
  </div>
</template>

<script>
  import { success, warning, error } from "@/utils/notification";
  import Page from '@/components/PageComponents.vue'
  export default {
    name: "discoverCompany",
    data() {
      return {
        typeValue: 'month',
        page: 1,
        status: '',
        meta: {},
        historyCompanyData: []
      }
    },
    components: {
      Page
    },
    methods: {
      editClickView(row) {
        this.$router.push({
          path: '/createBusinessCondition',
          query: {id: row.id}
        })
      },
      handleClickView(row) {
        this.$router.push({
          path: '/discoverStatistics',
          query: {
            organ_id: sessionStorage.organizationId - 0,
            demand_id: row.id
          }
        })
      },
      renderTypeHeader(h) {
        //下拉框选项
        let filters = [{value: 'month', label: '本月新增'}, {value: 'week', label: '本周新增'}, {value: 'day', label: '本日新增'}]
        return (
          h('el-select',{    //el-select实现下拉框
            on:{
              input: (value) => {//随着下拉框的不同，文字框里的内容改变
                this.typeValue = value;
                change: this.d_c_change();// 文字框里的内容 change事件
              },
            },
            props:{
              value: this.typeValue,  //文字框的内容取决于这个value
            },
          },  [   //下拉框里面填充选项，通过map遍历filters，为每一个选项赋值。
            filters.map(item => {
              return h("el-option", {
                props: {
                  value: item.value,
                  label: item.label
                }
              })
            })
          ])
        )
      },
      // 暂停追踪
      pauseClick(row) {
        this.$http.put(this.URL.demands + '/' + row.id + '/status', {organ_id: sessionStorage.organizationId - 0}).then(r => {
          this.pubCompanyFun()
        }).catch(_ => {
          error(_.response.data.message)
        })
      },
      pubCompanyFun() {
        let params = {
          organ_id: sessionStorage.organizationId - 0,
          page: this.page,
          status: this.status,
          date: this.typeValue
        }
        // this.status >= 1 && (params.status = this.status)
        this.$http.get(this.URL.demands, {params}).then(r => {
          this.meta = r.data.data.meta
          this.historyCompanyData = r.data.data.data
        })
      },
      currentPageChange(page) {
        this.page = page
        this.pubCompanyFun()
      },
      d_c_change() {
        this.page = 1
        this.pubCompanyFun()
      }
    },
    created() {
      this.pubCompanyFun()
    }
  }
</script>

<style scoped>
  .d-c{
    padding-bottom: 30px;
  }
  .d-c-title{
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .d-c-middle-wrap{
    display: flex;flex-direction: column;align-items: center;justify-content: space-around;
    width: 50%;height: 300px;
    border: 1px solid #bbb;border-radius: 20px;padding: 50px 70px;margin: 100px auto 30px;
  }
  .d-c-middle-title{
    line-height: 40px;
  }
  .d-c-middle-btn{
    width: 200px;
  }
  .d-c-bottom{
    padding-left: 60px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .d-c-bottom-title{
    margin-bottom: 30px;
  }
</style>
<style lang="scss">
  .d-c .el-radio__inner{
    width: 18px;
    height: 18px;
  }
  .d-c .el-radio__inner::after{
    width: 5px;
    height: 5px;
  }
  .d-c {
    .el-input__inner{
      height: 30px;line-height: 30px;
    }
    .el-input__suffix{
      right: 10px;
    }
  }

  .discover-table{
    .el-input.el-input--suffix{
      margin-top: 10px;
    }
    .has-gutter tr th{
      padding: 0 !important;
    }
  }
</style>
